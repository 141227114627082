import { createLightbox } from '../../lib/lightbox'
import { useEffect, useState } from 'react'
import Image from '../Image'

const Cinematography = () => {
  const [photosMovieOne, setPhotosMovieOne] = useState()
  const auth = btoa(process.env.REACT_APP_CLOUDINARY_API_KEY + ':' + process.env.REACT_APP_CLOUDINARY_SECRET_KEY)

  useEffect(() => {
    fetch(`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/list/movie_one.json`, {
      headers: {
        'Authorization': 'Basic ' + auth,
      },
      method: 'GET',
    }).then((response) => response.json()).then((data) => {
      setPhotosMovieOne(data)
      createLightbox()
      }
    )
  },[auth])

  return (
    <div>
      <div className="mb-12">
        <p className="xxl:text-2xl">Memory of a Girl</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-y-2 masonry masonry-home mt-2">
          {photosMovieOne && photosMovieOne?.resources.map((photo) => {
            return (
              <div className="flex flex-col" key={photo.public_id}>
                <Image key={photo.public_id} url={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/q_50/${photo.public_id}`}
                       description={photo.context?.custom?.caption} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Cinematography