import './App.css'
import Header from './components/Header'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Cinematography from './components/pages/Cinematography'
import Bio from './components/pages/Bio'
import Photography from './components/pages/Photography'
import NotFoundPage from './components/pages/404'
import {
  Routes,
  Route
} from "react-router-dom"

const App = () => {
  return (
    <div className="mx-auto px-6 md:px-12 xl:px-24 font-sans content">
      <Header />
      <Navbar />
        <Routes>
          <Route exact path='/' element={<Cinematography />} />
          <Route exact path='/photography' element={<Photography />}/>
          <Route exact path="/contact" element={<Bio />}/>
          <Route path="/*" element={<NotFoundPage />}/>
        </Routes>
      <Footer />
    </div>
  )
}

export default App
