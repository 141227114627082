import MenuItem from './MenuItem'

const Navbar = () => {
  const navItems = [
   {
      name: 'CINEMATOGRAPHY',
      url: '/',
    },
   {
      name: 'PHOTOGRAPHY',
      url: '/photography',
    },
   {
      name: 'CONTACT',
      url: '/contact',
    },
    {
      name: 'INSTAGRAM',
      url: 'https://www.instagram.com/jokderoo/',
    },
  ]

  return(
    <nav>
      <div className="pb-16 xxl:pb-32 text-center ">
        <ul className="grid grid-cols-1 md:grid-cols-4 md:flex md:flex-row justify-center break-words">
          {navItems.map((item, key) => {
            return (
              <MenuItem name={item.name} url={item.url} id={key} key={key} />
            )
          })}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar