import { NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <header>
        <div className="pb-2">
          <div className="pt-12 pb-6 md:pb-10">
            <div className="flex flex-col text-center">
              <div className="mb-3">
                <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl xxl:text-8xl font-serif">
                  <NavLink to="/">JOKKE DE ROO</NavLink>
                </h1>
              </div>
              <div className="xl:text-xl xxl:text-2xl">
                <p>Cinematography & Photography</p>
              </div>
            </div>
          </div>
        </div>
    </header>
  )
}

export default Header
