export const createLightbox = () => {
  const lightbox = document.createElement('div')
  lightbox.id = 'lightbox'
  document.body.appendChild(lightbox)

  const images = document.querySelectorAll('.lightbox-img')

  images.forEach((image) => {
    image.addEventListener('click', e => {
      lightbox.classList.add('active')
      const img = document.createElement('img')
      img.src = image.src
      while (lightbox.firstChild) {
        lightbox.removeChild(lightbox.firstChild)
      }
      lightbox.appendChild(img)
    })
  })

  lightbox.addEventListener('click', e => {
    return lightbox.classList.remove('active')
  })
}