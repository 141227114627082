const Image = ({ url, description }) => {
  return (
    <div className="img-container items-baseline relative">
      <img src={url} alt={description} className="lightbox-img cursor-pointer" />
      {description &&
        <div className="absolute z-10 text-center cursor-pointer items-center mt-4">
          <p className="hidden font-extrabold text-2xl md:text-3xl w-full">{description}</p>
        </div>
      }
    </div>
  )
}

export default Image