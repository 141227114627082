const Footer = () => {
  return (
    <footer className="pt-16">
      <div className="text-center py-8 border-t flex flex-row justify-center">
        <p className="mr-4 -mt-0.5 block  xxl:text-xl">Copyright © 2022 JOKKE DE ROO</p>
        <a href="https://www.instagram.com/jokderoo/"  target="_blank" rel="noreferrer">
          <svg width="20pt" height="20pt" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="m536.67 0h-373.34c-24.754 0-48.492 9.832-65.996 27.336-17.504 17.504-27.336 41.242-27.336 65.996v373.34c0 24.754 9.832 48.492 27.336 65.996 17.504 17.504 41.242 27.336 65.996 27.336h373.34c24.754 0 48.492-9.832 65.996-27.336 17.504-17.504 27.336-41.242 27.336-65.996v-373.34c0-24.754-9.832-48.492-27.336-65.996-17.504-17.504-41.242-27.336-65.996-27.336zm46.668 466.67h-0.003907c0 12.375-4.9141 24.246-13.668 32.996-8.75 8.7539-20.621 13.668-32.996 13.668h-373.34c-12.375 0-24.246-4.9141-32.996-13.668-8.7539-8.75-13.668-20.621-13.668-32.996v-373.34c0-12.375 4.9141-24.246 13.668-32.996 8.75-8.7539 20.621-13.668 32.996-13.668h373.34c12.375 0 24.246 4.9141 32.996 13.668 8.7539 8.75 13.668 20.621 13.668 32.996z"/>
            <path d="m350 140c-37.129 0-72.738 14.75-98.996 41.004-26.254 26.258-41.004 61.867-41.004 98.996s14.75 72.738 41.004 98.996c26.258 26.254 61.867 41.004 98.996 41.004s72.738-14.75 98.996-41.004c26.254-26.258 41.004-61.867 41.004-98.996s-14.75-72.738-41.004-98.996c-26.258-26.254-61.867-41.004-98.996-41.004zm0 233.33c-24.754 0-48.492-9.832-65.996-27.336s-27.336-41.242-27.336-65.996 9.832-48.492 27.336-65.996 41.242-27.336 65.996-27.336 48.492 9.832 65.996 27.336 27.336 41.242 27.336 65.996-9.832 48.492-27.336 65.996-41.242 27.336-65.996 27.336z"/>
            <path d="m536.67 128.33c0 19.332-15.672 35-35 35-19.332 0-35-15.668-35-35 0-19.328 15.668-35 35-35 19.328 0 35 15.672 35 35"/>
          </g>
        </svg>
        </a>
      </div>
    </footer>
  )
}

export default Footer