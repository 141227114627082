const Bio = () => {
  return (
    <div className="lg:px-32 xl:px-64 xxl:text-2xl text-center">
      <div className="lg:pr-8">
        <h2 className="font-bold mb-24">Jokke De Roo is a born in Bruges, based in Antwerp Photographer and Cinematographer.</h2>
      </div>
      <div className="pt-8">
        <p className="pb-2">
          <a href="tel:+32493625129" className="inline-link">+32 493 62 51 29</a>
        </p>
        <p>
          <a href="mailto:deroojokke@hotmail.com" target="_blank" rel="noreferrer" className="inline-link">deroojokke@hotmail.com</a>
        </p>
      </div>
    </div>
  )
}

export default Bio