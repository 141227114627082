import Image from '../Image'
import { createLightbox } from '../../lib/lightbox'
import { useEffect, useState } from 'react'

const Photography = () => {
  const [photos, setPhotos] = useState()
  const auth = btoa(process.env.REACT_APP_CLOUDINARY_API_KEY + ':' + process.env.REACT_APP_CLOUDINARY_SECRET_KEY)

  useEffect(() => {
   fetch(`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/list/photography.json`, {
      headers: {
        'Authorization': 'Basic ' + auth,
      },
      method: 'GET',
    }).then((response) => response.json()).then((data) => {
      setPhotos(data)
      createLightbox()
    }
   )
  },[auth])

  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-3 masonry">
      {photos && photos?.resources.map((photo) => {
        return (
          <Image key={photo.public_id} url={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/q_50/${photo.public_id}`}
                 description={photo.context?.custom?.caption} />
        )
      })}
    </div>
  )
}

export default Photography