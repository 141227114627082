import { NavLink } from 'react-router-dom'

const MenuItem = ({ name, url }) => {

  return (
    <li className="col-span-2 py-2 md:py-0 px-4 text-sm md:text-base xxl:text-2xl">
      {name !== 'INSTAGRAM' ? <NavLink to={url} activeclassname="active">{name}</NavLink>
        :
        <a href={url}  target="_blank" rel="noreferrer" className="flex justify-center">{name}</a>
      }
    </li>
  )
}

export default MenuItem